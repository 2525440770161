<template>
  <CRow>


    <CCol col="12" md="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center"/><b> Atención </b><small> <b> lee cuidadosamente las indicaciónes señaladas:</b> <br> <b> En un lapso de 7 días naturales previos a la fecha de examen de admisión de tu ficha, CENEVAL te hará llegar a tu cuenta de correo toda la información correspondiente al EXANI-II y te entregarán tus códigos de acceso para el Examen desde casa (Examen CENEVAL), al correo que registraste para tu tramite de ficha de admision. </b> <br>  <b> Recuerda que el examen de admisión es en línea (Computadora personal/CPU). </b></small>
        </CCardHeader>
        <CCardBody>
          <CAlert show color="success">

            <p>
             Tus documentos han sido validados puedes generar tu ficha en el siguente enlace:

            </p>
            <p>
              <CButton @click="generar_pdf()" block color="facebook">
                Generar Ficha
              </CButton>
            </p>

             <!-- <CCol sm="12" style="text-align: center; margin-top: 20px;">
                <p>
                  <CButton  style="width: 200px; margin-left: auto; margin-right: auto" color="success" @click="salir()" block>Salir</CButton>
                </p>

              </CCol> -->

            <hr>
            <p class="mb-0">

            </p>
          </CAlert>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
export default {
  name: 'Alerts',
  data () {
    return {
      id:localStorage.getItem('id'),
      dismissSecs: 10,
      dismissCountDown: 10,
      alert1: true,
      alert2: true
    }
  },
  methods: {

    // salir(){
     
    //          this.$router.push('/pages/login');
             
    // },
    generar_pdf(){

      window.open('https://cdn.upbicentenario.edu.mx/api/pdf_ficha/' + this.id)

    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    showDismissibleAlerts () {
      ['alert1', 'alert2'].forEach(alert => this[alert] = true)
    }
  }
}
</script>
